import React from "react"
import moment from "moment"
import Card from "elements/Card"
import Message from "elements/Message"
import Section from "elements/Section"
import { useAnnouncements } from "./hooks/useAirtableAnnouncements"
export const Announcements = () => {
  const announcements = useAnnouncements()
  let title = "Announcements"
  return (
    <Card className="my-1">
      <Section
        title={title}
        children={
          <Message color="primary" align="left">
            <strong>
              {moment(announcements[0].date).format("YYYY-MM-DD")}
            </strong>
            <br />
            {announcements[0].content}
          </Message>
        }
        id={""}
        addOns={""}
      ></Section>
    </Card>
  )
}
