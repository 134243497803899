import { useStaticQuery, graphql } from "gatsby"

export const useCardButtonImages = () => {
  const data = useStaticQuery(graphql`
    {
      nurse: file(relativePath: { eq: "icons/nurse.png" }) {
        childImageSharp {
          fixed(width: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      doctor: file(relativePath: { eq: "icons/doctor.png" }) {
        childImageSharp {
          fixed(width: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      newconsult: file(relativePath: { eq: "icons/examination.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      inventory: file(relativePath: { eq: "icons/inventory.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      validatemedcert: file(relativePath: { eq: "icons/medical-file.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      trainingmaterials: file(
        relativePath: { eq: "icons/training-materials.png" }
      ) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      pendingubmission: file(
        relativePath: { eq: "icons/pending-submission.png" }
      ) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      incomplete: file(relativePath: { eq: "icons/incomplete.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}
