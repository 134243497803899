import React from "react"
import Card from "elements/Card"
import Section from "elements/Section"

export const PendingTasks = () => {
  let title = "Pending Tasks"
  return (
    <Card>
      <Section title={title}>
        <div className="is-flex">
          <h2 className="is-flex has-text-primary mx-1">03</h2>
          <p className="is-flex has-text-primary">
            New MedCert Validation Requests
          </p>
        </div>
        <div className="is-flex">
          <h2 className="is-flex has-text-primary mx-1">01</h2>
          <p className="is-flex has-text-primary">
            Scheduled Teleconsult
            <br />
            Today at 2pm
          </p>
          <hr />
        </div>
      </Section>
    </Card>
  )
}
