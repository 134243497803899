import React from "react"

import DashboardLayout from "layout/DashboardLayout"

import { PendingTasks } from "./PendingTasks"
import { QuickLinks } from "./QuickLinks"
import { Announcements } from "./Announcements"
import { OngoingShift } from "./OngoingShift"

const Dashboard = ({ pageContext }) => {
  return (
    <DashboardLayout pageContext={pageContext}>
      <div className="columns is-gapless">
        <div className="column is-three-fifths">
          <Announcements />
          <QuickLinks />
        </div>
        <div className="column">
          <OngoingShift />
          <PendingTasks />
        </div>
      </div>
    </DashboardLayout>
  )
}

export default Dashboard
