import React from "react"

import Card from "elements/Card"
import Section from "elements/Section"
import Button from "elements/Button"
import Img from "gatsby-image"
import { navigate } from "gatsby"
import { useCardButtonImages } from "./hooks/useCardButtonImages"
import { getSignedInUser } from "../Auth/services/user"
import { getElapsedTime } from "services/general"

export const OngoingShift = () => {
  const data = useCardButtonImages()
  let title = "Ongoing Shift"
  let { activeMedstaff } = getSignedInUser()

  const getImage = (designation) => {
    switch (designation) {
      case "RN":
        return data.nurse.childImageSharp.fixed
      case "MD":
        return data.doctor.childImageSharp.fixed
      default:
        return data.doctor.childImageSharp.fixed
    }
  }

  return (
    <Card className="my-1">
      <Section title={title}>
        {activeMedstaff?.slice(0, 2).map((medstaff) => {
          return (
            <Card className="my-1">
              <div className="is-flex has-text-weight-bold is-align-items-center">
                <div className="is-flex mx-1">
                  <Img loading="eager" fixed={getImage(medstaff.designation)} />
                </div>
                <p className="is-flex has-text-primary">
                  {medstaff.firstName + " " + medstaff.lastName}
                </p>
                <p className="ml-1 is-flex has-text-primary">
                  {getElapsedTime({
                    startTime: medstaff.timesheet.StartTimeLocalized,
                  })}
                </p>
              </div>
            </Card>
          )
        })}
        {activeMedstaff?.length !== 0 && (
          <Button
            className="is-flex has-text-primary"
            onClick={() => navigate("/deputy/employees")}
            isFullwidth
          >
            See All Ongoing Shifts
          </Button>
        )}
      </Section>
    </Card>
  )
}
