import React from "react"
import { navigate } from "gatsby"
import Img from "gatsby-image"
import Card from "elements/Card"
import Section from "elements/Section"
import groupedCards from "./utils/groupedCards.json"
import { useCardButtonImages } from "./hooks/useCardButtonImages"

export const QuickLinks = () => {
  const data = useCardButtonImages()
  let title = "Quick Links"
  return (
    <Card>
      <Section title={title}>
        {groupedCards.map((group) => {
          return (
            <div className="columns">
              {group.map((card) => {
                return (
                  <div
                    className="column"
                    aria-hidden="true"
                    onClick={() => {
                      navigate(card.link)
                    }}
                    role="button"
                  >
                    <Card>
                      <div className="is-flex has-text-weight-bold is-align-items-center">
                        <div className="is-flex mx-1">
                          <Img
                            loading="eager"
                            fixed={data[card.logo].childImageSharp.fixed}
                          />
                        </div>
                        <p className="is-flex has-text-primary has-text-centered">
                          {card.content}
                        </p>
                      </div>
                    </Card>
                  </div>
                )
              })}
            </div>
          )
        })}
      </Section>
    </Card>
  )
}
