import { useStaticQuery, graphql } from "gatsby"
import { flattenNode } from "services/arrays"

export const useAnnouncements = () => {
  let announcements = useStaticQuery(graphql`
  query getAnnouncement {
    allAirtableallAnnouncements(
        filter: {data: {Status: {eq: "Active"}}}, sort: {order: DESC, fields: data___Date}, limit: 1) {
      nodes {
        data {
          Content
          Date
          Status
        }
      }
    }
  }
  `)

  return flattenNode(announcements?.allAirtableallAnnouncements)
}